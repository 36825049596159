import { calculatePaymentCycle, changeCurrency } from "./helpersFunc";

const calculateProductPrice = (product) => {
    if (product.type === "domain") {
        return (parseFloat(product.product_price_dict[0].content.register_price + product.product_price_dict[0].content.premium_dns_price + product.product_price_dict[0].content.whois_price).toFixed(2));
    } else if (product.payment_cycle === 1) {
        return (product.component_total_price_dict?.one_month_price + product.product_price_dict.one_month_price);

    } else if (product.payment_cycle === 3) {
        return (product.component_total_price_dict?.three_month_price + product.product_price_dict.three_month_price);

    } else if (product.payment_cycle === 6) {
        return (product.component_total_price_dict?.six_month_price + product.product_price_dict.six_month_price);

    } else if (product.payment_cycle === 12) {
        return (product.component_total_price_dict?.one_year_price + product.product_price_dict.one_year_price);

    } else if (product.payment_cycle === 24) {
        return (pproduct.component_total_price_dict?.two_year_price + product.product_price_dict.two_year_price);

    } else {
        return (product.component_total_price_dict?.three_year_price + product.product_price_dict.three_year_price);

    }
};

export const googleEventViewBasket = (data) => {
    gtag("event", "view_cart", {
        ecommerce: {
            currency: "TRY",
            value: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", data.basket.payment_methods[0].credit_card[0].method_list.paid_price, data.basket.payment_methods[0].credit_card[0].billing_infos.currency_code)),
            items:
                data.product_list.map((item, index) => {
                    return {
                        item_id: item.product_id,
                        item_basket_id: item.id,
                        item_name: item.type === "domain" ? "domain " + item.domain : item.group_name + " " + item.product_info.product_name,
                        affiliation: "",
                        coupon: "",
                        discount: 0,
                        index: index,
                        item_brand: item.type === "ssl" ? item.product_info.product_name : "Makdos",
                        item_category: item.type_name,
                        item_category2: item.group_name,
                        item_category3: "",
                        item_category4: "",
                        item_category5: "",
                        item_list_id: item.product_info.product_type_id,
                        item_list_name: item.group_name,
                        item_variant: "",
                        location_id: "",
                        price: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", calculateProductPrice(item))),
                        payment_cycle: item?.payment_cycle,
                        quantity: item.quantity
                    }
                })
        }
    });
};

export const googleEventBeginCheckout = (data, payment_type) => {
    gtag("event", "begin_checkout", {
        ecommerce: {
            currency: "TRY",
            value: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", data.basket.payment_methods[0].credit_card[0].method_list.paid_price, data.basket.payment_methods[0].credit_card[0].billing_infos.currency_code)),
            payment_type: payment_type,
            items:
                data.product_list.map((item, index) => {
                    return {
                        item_id: item.product_id,
                        item_basket_id: item.id,
                        item_name: item.type === "domain" ? "domain " + item.domain : item.group_name + " " + item.product_info.product_name,
                        affiliation: "",
                        coupon: "",
                        discount: 0,
                        index: index,
                        item_brand: item.type === "ssl" ? item.product_info.product_name : "Makdos",
                        item_category: item.type_name,
                        item_category2: item.group_name,
                        item_category3: "",
                        item_category4: "",
                        item_category5: "",
                        item_list_id: item.product_info.product_type_id,
                        item_list_name: item.group_name,
                        item_variant: "",
                        location_id: "",
                        price: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", calculateProductPrice(item))),
                        payment_cycle: item?.payment_cycle,
                        quantity: item.quantity
                    }
                })
        }
    });
}

export const googleEventAddPaymentInfo = (data, payment_type, email) => {
    gtag("event", "add_payment_info", {
        ecommerce: {
            currency: "TRY",
            value: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", data.basket.payment_methods[0].credit_card[0].method_list.paid_price, data.basket.payment_methods[0].credit_card[0].billing_infos.currency_code)),
            payment_type: payment_type,
            email: email,
            items:
                data.product_list.map((item, index) => {
                    return {
                        item_id: item.product_id,
                        item_basket_id: item.id,
                        item_name: item.type === "domain" ? "domain " + item.domain : item.group_name + " " + item.product_info.product_name,
                        affiliation: "",
                        coupon: "",
                        discount: 0,
                        index: index,
                        item_brand: item.type === "ssl" ? item.product_info.product_name : "Makdos",
                        item_category: item.type_name,
                        item_category2: item.group_name,
                        item_category3: "",
                        item_category4: "",
                        item_category5: "",
                        item_list_id: item.product_info.product_type_id,
                        item_list_name: item.group_name,
                        item_variant: "",
                        location_id: "",
                        price: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", calculateProductPrice(item))),
                        payment_cycle: item?.payment_cycle,
                        quantity: item.quantity
                    }
                })
        }
    });
}


export const googleEventRemoveBasketItem = (data, removedItemID) => {
    let findItem = data.product_list.find(item => item.id === removedItemID);
    gtag("event", "remove_from_cart", {
        ecommerce: {
            currency: "TRY",
            value: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", data.basket.payment_methods[0].credit_card[0].method_list.paid_price, data.basket.payment_methods[0].credit_card[0].billing_infos.currency_code)),
            items: [
                {
                    item_id: findItem.product_id,
                    item_basket_id: findItem.id,
                    item_name: findItem.type === "domain" ? "domain " + findItem.domain : findItem.group_name + " " + findItem.product_info.product_name,
                    affiliation: "",
                    coupon: "",
                    discount: 0,
                    index: 1,
                    item_brand: findItem.type === "ssl" ? findItem.product_info.product_name : "Makdos",
                    item_category: findItem.group_name,
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: findItem.product_info.product_type_id,
                    item_list_name: findItem.type,
                    item_variant: "",
                    location_id: "",
                    price: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", calculateProductPrice(findItem))),
                    quantity: findItem.quantity
                }
            ]
        }
    });
};




export const googleEventAddBasket = async (data, totalPrice, currency, components = [], defaultPaymentCycle, basketId) => {
    let newComps = []
    await components.map((item) => {
        newComps.push({
            id: item.id,
            configurationName: item.configurationName,
            componentId: item.componentId,
            CompName: item.CompName,
            count: item.count,
            currency: item?.details?.currency ? item?.details?.currency : "tl",
            price: parseFloat(item.price),
            price_tl: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", item.price, item?.details?.currency)),
            details: item.details
        })
    })

    const eventData = {
        ecommerce: {
            currency: "TRY",
            value: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", totalPrice, currency)),
            items: [
                {
                    item_id: data.id,
                    item_basket_id: basketId,
                    item_name: data.group_group.group_name + " " + data.product_name,
                    affiliation: "",
                    coupon: "",
                    discount: 0,
                    index: 1,
                    item_brand: data.group_group.group_name === "ssl" ? data.product_name : "Makdos",
                    item_category: data?.group_group?.item_category_dict?.item_category ? data?.group_group?.item_category_dict?.item_category : data?.group_group?.group_name,
                    item_category2: data?.group_group?.item_category_dict?.item_category2 ? data?.group_group?.item_category_dict?.item_category2 : "",
                    item_category3: data?.group_group?.item_category_dict?.item_category3 ? data?.group_group?.item_category_dict?.item_category3 : "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: data?.product_type_id ? data?.product_type_id : data.product_info.product_type_id,
                    item_list_name: data.group_group.group_name,
                    item_variant: "",
                    location_id: "",
                    price: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", totalPrice, currency)),
                    payment_cycle: calculatePaymentCycle(defaultPaymentCycle),
                    quantity: 1,
                    parts: newComps

                }
            ]
        }
    }

    gtag("event", "add_to_cart", eventData);
}


export const googleEventAddBasketSSL = (data, totalPrice, currency, defaultPaymentCycle, basketId) => {
    gtag("event", "add_to_cart", {
        ecommerce: {
            currency: "TRY",
            value: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", totalPrice, currency)),
            items: [
                {
                    item_id: data.product_id,
                    item_basket_id: basketId,
                    item_name: "SSL " + data.component,
                    affiliation: "",
                    coupon: "",
                    discount: 0,
                    index: 1,
                    item_brand: data.component,
                    item_category: "Extra",
                    item_category2: "SSL",
                    item_category3: data.component,
                    item_category4: "",
                    item_category5: "",
                    item_list_id: data?.id,
                    item_list_name: "SSL",
                    item_variant: "",
                    location_id: "",
                    price: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", totalPrice, currency)),
                    quantity: 1,
                    payment_cycle: defaultPaymentCycle
                }
            ]
        }
    });
}



export const googleEventAddBasketDomain = async (data, totalPrice, currency, components = [], defaultPaymentCycle, basketId) => {
    let newComps = []
    await components.map((item) => {
        newComps.push({
            id: item.id,
            configurationName: item.configurationName,
            componentId: item.componentId,
            CompName: item.CompName,
            count: item.count,
            currency: item.details.currency,
            price: parseFloat(item.price),
            price_tl: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", item.price, item?.details?.currency)),
            details: item.details
        })
    })
    gtag("event", "add_to_cart", {
        ecommerce: {
            currency: "TRY",
            value: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", totalPrice, currency)),
            items: [
                {
                    item_id: data.product_id,
                    item_basket_id: basketId,
                    item_name: "domain " + data.domain_name,
                    affiliation: "",
                    coupon: "",
                    discount: 0,
                    index: 1,
                    item_brand: "Makdos",
                    item_category: "domain",
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "",
                    item_list_name: "domain",
                    item_variant: "",
                    location_id: "",
                    price: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", totalPrice, currency)),
                    payment_cycle: calculatePaymentCycle(defaultPaymentCycle),
                    quantity: 1,
                    parts: newComps
                }
            ]
        }
    });
}


export const googleEventConfigurationDomain = (data, totalPrice, currency, location_id) => {
    gtag("event", "select_item", {
        ecommerce: {
            currency: "TRY",
            value: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", totalPrice, currency)),
            items: [
                {
                    item_id: data.product_id,
                    item_name: "domain " + data.domain_name,
                    affiliation: "",
                    coupon: "",
                    discount: 0,
                    index: 1,
                    item_brand: "Makdos",
                    item_category: "domain",
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "",
                    item_list_name: "domain",
                    item_variant: "",
                    location_id: location_id,
                    price: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", totalPrice, currency)),
                    quantity: 1
                }
            ]
        }
    });
}



export const googleEventConfiguration = (data, totalPrice, currency, location_id) => {
    const eventData = {
        ecommerce: {
            currency: "TRY",
            value: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", totalPrice, currency)),
            items: [
                {
                    item_id: data.id,
                    item_name: data?.group_group?.group_name + " " + data.product_name,
                    affiliation: "",
                    coupon: "",
                    discount: 0,
                    index: 1,
                    item_brand: data?.group_group?.group_name === "ssl" ? data.product_name : "Makdos",
                    item_category: data?.group_group?.item_category_dict?.item_category ? data?.group_group?.item_category_dict?.item_category : data?.group_group?.group_name,
                    item_category2: data?.group_group?.item_category_dict?.item_category2 ? data?.group_group?.item_category_dict?.item_category2 : "",
                    item_category3: data?.group_group?.item_category_dict?.item_category3 ? data?.group_group?.item_category_dict?.item_category3 : "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: data?.product_type_id ? data?.product_type_id : data?.product_info?.product_type_id,
                    item_list_name: data?.group_group?.group_name,
                    item_variant: "",
                    location_id: location_id,
                    price: parseFloat(changeCurrency(JSON.parse(localStorage.getItem("__currencyRates")), "₺", totalPrice, currency)),
                    quantity: 1
                }
            ]
        }
    }
    gtag("event", "select_item", eventData);
}

export const googleEventAdBlock = () => {
    gtag("event", "adBlock", {
        event_category: "AdBlock",
        event_label: "AdBlock Detected",
    });
}

export const googleEventPurchase = (data) => {
    gtag("event", "purchase", {
        ecommerce: {
            transaction_id: data.order_no,
            value: data.project_summary?.project_total_value,
            tax: data?.project_summary?.project_total_value_tax?.tax?.total_price_only_tax ? data?.project_summary?.project_total_value_tax?.tax?.total_price_only_tax : 0,
            fee: data?.project_summary?.project_total_value_tax?.fee?.total_price_only_fee_rate ? data?.project_summary?.project_total_value_tax?.fee?.total_price_only_fee_rate : 0,
            commission: data?.project_summary?.project_total_value_tax?.transaction?.total_price_only_transaction_rate ? data?.project_summary?.project_total_value_tax?.transaction?.total_price_only_transaction_rate : 0,
            shipping: 0,
            currency: "TRY",
            email: data.customer_email,
            items: data.basket_info.map((item) => {
                return {
                    item_id: item.product.id,
                    basket_id: item.basket_product_id,
                    item_name: item.group_name + " " + item.product.content.product_name,
                    affiliation: item.group_name,
                    price: item.with_all_components_total_price,
                    quantity: 1
                }
            })
        }
    });

}